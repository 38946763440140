import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import avatar1 from "assets/avatar/avatar-1.jpg";
import avatar2 from "assets/avatar/avatar-2.jpg";
import avatar3 from "assets/avatar/avatar-3.jpg";
import avatar4 from "assets/avatar/avatar-4.jpg";
import avatar5 from "assets/avatar/avatar-5.jpg";
import icon100 from "assets/icon/100.svg";
import styles from "./our-client.module.css";
import "./our-client.css";

const FeedBack = ({ review, name, country, avatar }) => (
  <div>
    <div className={styles.feedbackWrapper}>
      <p>{review}</p>
      <div className="flex items-center">
        <img className="avatar" src={avatar} alt="" />
        <h6 className="name">
          {name}, <span>{country}</span>
        </h6>
      </div>
    </div>
  </div>
);

function OurClient() {
  const { t } = useTranslation();
  return (
    <div
      id="our-client"
      style={{
        paddingTop: "150px",
      }}
    >
      <div className={styles.container}>
        <div className="flex justify-center items-center flex-col w-full">
          <img src={icon100} alt="" />
          <h2 className={styles.title1}>
            <span>{t("landingPage.ourClient.client")}</span>
            {t("landingPage.ourClient.loveClient")}
          </h2>
          <h2 className={styles.title2}>
            {t("landingPage.ourClient.kindWordsFromOurToeicXClients")}
          </h2>
        </div>
      </div>
      <Slider
        style={{ maxWidth: "1740px", margin: "0 auto" }}
        dots
        infinite
        speed={500}
        autoplay
        autoplaySpeed={2000}
        slidesToShow={3}
        slidesToScroll={2}
        arrows={false}
        className={styles.feedBack}
        appendDots={(dots) => <ul>{dots}</ul>}
        customPaging={(i) => (
          <button type="button" role="tab" tabIndex="0" aria-selected="true">
            {i}
          </button>
        )}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
        ]}
      >
        <FeedBack
          review={t("landingPage.ourClient.feedBack1")}
          name="Hong Nga"
          country="VN"
          avatar={avatar1}
        />
        <FeedBack
          review={t("landingPage.ourClient.feedBack2")}
          name="Tuy Nhi"
          country="VN"
          avatar={avatar2}
        />
        <FeedBack
          review={t("landingPage.ourClient.feedBack3")}
          name="Ngoc Quyen"
          country="VN"
          avatar={avatar3}
        />
        <FeedBack
          review={t("landingPage.ourClient.feedBack4")}
          name="Hoang Emy"
          country="VN"
          avatar={avatar4}
        />
        <FeedBack
          review={t("landingPage.ourClient.feedBack5")}
          name="Nhat Nhi"
          country="VN"
          avatar={avatar5}
        />
      </Slider>
    </div>
  );
}

export default OurClient;
