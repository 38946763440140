import { useTranslation } from "react-i18next";
import shape18 from "assets/shape/18.svg";
import shape19 from "assets/shape/19.svg";
import shape20 from "assets/shape/20.svg";
import shape21 from "assets/shape/21.svg";
import icon20 from "assets/icon/20.svg";
import icon21 from "assets/icon/21.svg";
import icon22 from "assets/icon/22.svg";
import styles from "./fancy-feature.module.css";

function FancyFeature() {
  const { t } = useTranslation();
  return (
    <div
      id="about-toeicx"
      style={{
        padding: "150px 15px 0px 15px",
      }}
    >
      <div className={styles.container}>
        <img
          src={shape18}
          alt=""
          className={`${styles.shapes} ${styles.shapeRight}`}
        />
        <img
          src={shape19}
          alt=""
          className={`${styles.shapes} ${styles.shapeLeft}`}
        />
        <div className="flex justify-center items-center flex-col">
          <p className={styles.title1}>
            {t("landingPage.fancyFeature.category")}
          </p>
          <h2 className={styles.title2}>
            {t("landingPage.fancyFeature.title")}
          </h2>
        </div>
        <div className="relative">
          <img
            src={shape20}
            alt=""
            className={`${styles.shapes} ${styles.shapeOne}`}
          />
          <img
            src={shape21}
            alt=""
            className={`${styles.shapes} ${styles.shapeTwo}`}
          />
          <div className={styles.content}>
            <div className={styles.contentItemContainer}>
              <div className={styles.contentItem}>
                <img src={icon20} alt="" className="h-[72px]" />
                <h6 className={styles.title}>
                  {t("landingPage.fancyFeature.varietyToeicTest")}
                </h6>
                <p className={styles.contentDetail}>
                  {t("landingPage.fancyFeature.varietyToeicTestDescription")}
                </p>
              </div>
            </div>

            <div className={styles.contentItemContainer}>
              <div className={styles.contentItem}>
                <img src={icon21} alt="" className="h-[72px]" />
                <h6 className={styles.title}>
                  {t("landingPage.fancyFeature.toeicGrammar")}
                </h6>
                <p className={styles.contentDetail}>
                  {t("landingPage.fancyFeature.toeicGrammarDescription")}
                </p>
              </div>
            </div>
            <div className={styles.contentItemContainer}>
              <div className={styles.contentItem}>
                <img src={icon22} alt="" className="h-[72px]" />
                <h6 className={styles.title}>
                  {t("landingPage.fancyFeature.analysisEvaluation")}
                </h6>
                <p className={styles.contentDetail}>
                  {t("landingPage.fancyFeature.analysisEvaluationDescription")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FancyFeature;
