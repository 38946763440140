import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Modal } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import googlePlayIcon from "assets/icon/google-play-badge.svg";
import appStoreIcon from "assets/icon/app-store-badge.svg";
import momoQRCode from "assets/momo-qr-code.png";
import styles from "./billing-info.module.css";

const BillingInfo = ({ btnText, buttonActive, packCode }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    if (packCode !== "FREE") {
      setIsModalVisible(true);
    } else {
      window.location.href = "#fancy-banner";
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div
        className={`${styles.button} ${buttonActive && styles.buttonActive}`}
        onClick={showModal}
      >
        {btnText}
      </div>
      <Modal
        className={styles.checkoutModal}
        title={t("landingPage.billingInfo.billingInformation")}
        open={isModalVisible}
        onCancel={closeModal}
        footer={null}
      >
        <p className={styles.title}>{t("landingPage.billingInfo.title")}</p>
        <p className={styles.subTitle}>
          {t("landingPage.billingInfo.subTitle")}
        </p>

        <div className={styles.wrapPaymentOptions}>
          <div className={styles.wrapPaymentOptionLeft}>
            <div className={styles.wrapPaymentOptionContent}>
              <p className={styles.titlePaymentOption}>
                {t("landingPage.billingInfo.bankTransfer")}
              </p>
              <p>
                <b>{t("landingPage.billingInfo.bank")}:</b> VIETCOMBANK
              </p>
              <p>
                <b>{t("landingPage.billingInfo.accountNumber")}:</b>{" "}
                19035311130012
              </p>
              <p>
                <b>{t("landingPage.billingInfo.accountName")}:</b> LE NHAT TANH
              </p>
              <p>
                <b>{t("landingPage.billingInfo.transferContent")}:</b> TOEICX{" "}
                {packCode} {"<Email User>"}
              </p>
            </div>
          </div>
          <div className={styles.wrapPaymentOptionRight}>
            <div className={styles.wrapPaymentOptionContent}>
              <p className={styles.titlePaymentOption}>
                {t("landingPage.billingInfo.paymentOfMoMoWallet")}
              </p>
              <p>
                <b>{t("landingPage.billingInfo.phoneNumber")}</b> 0333880195
              </p>
              <p>
                <b>{t("landingPage.billingInfo.transferContent")}:</b> TOEICX{" "}
                {packCode} {"<Email User>"}
              </p>
              <img
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                width="200"
                height="200"
                src={momoQRCode}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className={styles.wrapSignUp}>
          <div className={styles.signUpContainer}>
            <div className={styles.signUpItem}>
              <h6>{t("landingPage.billingInfo.alreadyAMember")}</h6>
              <h2>{t("landingPage.billingInfo.downloadToeicXAndSignUp")}</h2>
              <a
                href="https://m.me/toeicX"
                className={styles.chatBtn}
                target="_blank"
                rel="noreferrer"
              >
                <MessageOutlined />{" "}
                <span className={styles.chatBtnText}>
                  {t("landingPage.billingInfo.chatWithUs")}
                </span>
              </a>
            </div>
            <div className={styles.downloadApp}>
              <a
                href="https://apps.apple.com/vn/app/toeic-x/id6605927171"
                className="text-white rounded-lg"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center justify-center">
                  <img className="p-1 h-[65px]" src={appStoreIcon} alt="" />
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.toeicx.app"
                className="text-white rounded-lg"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center justify-center">
                  <img className="p-1 h-[65px]" src={googlePlayIcon} alt="" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BillingInfo;
