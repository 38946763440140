import { useTranslation } from "react-i18next";
import googlePlayIcon from "assets/icon/google-play-badge.svg";
import appStoreIcon from "assets/icon/app-store-badge.svg";
import shape171 from "assets/shape/171.svg";
import shape172 from "assets/shape/172.svg";
import styles from "./fancy-banner.module.css";

function FancyBanner() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        paddingTop: "10px",
      }}
      id="fancy-banner"
    >
      <div className={styles.fancyBanner}>
        <div className={styles.container}>
          <div
            data-aos="fade-up"
            data-aos-duration="1200"
            className={`${styles.title1} w-2/3`}
          >
            <h2>
              <span>200k+</span>
              {t("landingPage.fancyBanner.customersHaveOurToeicX")}
            </h2>
            <p className={styles.title2}>
              {t("landingPage.fancyBanner.tryItFree")}
            </p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
            className="flex"
          >
            <a
              href="https://apps.apple.com/vn/app/toeic-x/id6605927171"
              className="p-1 px-2 text-white rounded-lg"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center justify-center">
                <img className="p-2 h-[65px]" src={appStoreIcon} alt="" />
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.toeicx.app"
              className="p-1 px-2 text-white rounded-lg"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center justify-center">
                <img className="p-2 h-[65px]" src={googlePlayIcon} alt="" />
              </div>
            </a>
          </div>
        </div>
        <img src={shape171} alt="" className={styles.shapesShapeOne} />
        <img src={shape172} alt="" className={styles.shapesShapeTwo} />
      </div>
    </div>
  );
}

export default FancyBanner;
