import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./contact.module.css";

function Contact() {
  const { t } = useTranslation();
  return (
    <div className="mt-24 border-t-2" id="contact-us">
      <div className={styles.container}>
        <div
          className={styles.logoContainer}
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="rubik-fonts logo">toeic X.</div>
        </div>
        <div className={styles.contactContainer}>
          <div className={styles.contactItem}>
            <h5 className={styles.title}>{t("landingPage.contact.link")}</h5>
            <div>
              <p className={styles.link}>
                <a href="#about-toeicx">
                  {t("landingPage.contact.aboutToeicX")}
                </a>
              </p>
              <p className={styles.link}>
                <a href="#faq">{t("landingPage.contact.faq")}</a>
              </p>
              <p className={styles.link}>
                <a href="#our-client">{t("landingPage.contact.ourClient")}</a>
              </p>
              <p className={styles.link}>
                <a href="#pricing-plan">
                  {t("landingPage.contact.pricingPlan")}
                </a>
              </p>
            </div>
          </div>
          <div className={styles.contactItem}>
            <h5 className={styles.title}>
              {t("landingPage.contact.contactInfo")}
            </h5>
            <div>
              <p className={styles.link}>
                220/2a Nguyen Xi, Phuong 26, Binh Thanh, TP HCM
              </p>
              <p className={styles.link}>admin@toeicx.com</p>
              <p className={styles.link}>+84 333 880 195</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <p>Copyright @2021 ToeicX inc.</p>
        <div className="flex gap-10">
          <Link to="/privacy-policy">Privacy policy</Link>
          <Link to="/terms-and-conditions">Terms of Conditions</Link>
        </div>
      </div>
    </div>
  );
}

export default Contact;
