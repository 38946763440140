/* eslint-disable react/button-has-type */
import { useTranslation } from "react-i18next";
import googlePlayIcon from "assets/icon/google-play-badge.svg";
import appStoreIcon from "assets/icon/app-store-badge.svg";
import ils09Img from "assets/shape/ils_09.svg";
import shape68 from "assets/shape/68.svg";
import shape69 from "assets/shape/69.svg";
import shape70 from "assets/shape/70.svg";
import shape71 from "assets/shape/71.svg";
import shape72 from "assets/shape/72.svg";
import shape73 from "assets/shape/73.svg";
import shape74 from "assets/shape/74.svg";
import shape75 from "assets/shape/75.svg";
import shape76 from "assets/shape/76.svg";
import shape77 from "assets/shape/77.svg";
import "./banner.css";

function Banner() {
  const { t } = useTranslation();
  return (
    <div id="home" className="pt-[150px] hero-banner relative">
      <div className="lg:max-w-[1170px] md:max-w-[720px] sm:max-w-[540px] mr-auto ml-auto flex items-center justify-center flex-col">
        <h1 className="rubik-fonts text-[50px] lg:text-[100px] md:text-[50px] text-center leading-none mb-0 font-normal">
          {t("landingPage.banner.title")}
        </h1>
        <div className="pt-[25px] pb-45 max-w-45 flex justify-center">
          <p className="flex text-[20px] lg:text-[28px] md:text-[20px] text-center rubik-fonts leading-relaxed font-light">
            {t("landingPage.banner.subTitle")}
          </p>
        </div>
        <div className="flex mt-[20px]">
          <a
            href="https://apps.apple.com/vn/app/toeic-x/id6605927171"
            className="p-1 px-2 text-white rounded-lg"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center justify-center">
              <img className="p-2 h-[65px]" src={appStoreIcon} alt="" />
            </div>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.toeicx.app"
            className="p-1 px-2 text-white rounded-lg"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center justify-center">
              <img className="p-2 h-[65px]" src={googlePlayIcon} alt="" />
            </div>
          </a>
        </div>

        <img src={ils09Img} alt="" className="illustration" />
      </div>

      <img src={shape68} alt="" className="hidden md:block shapes shape-one" />
      <img src={shape69} alt="" className="hidden md:block shapes shape-two" />
      <img
        src={shape70}
        alt=""
        className="hidden md:block shapes shape-three"
      />
      <img src={shape71} alt="" className="hidden md:block shapes shape-four" />
      <img src={shape72} alt="" className="hidden md:block shapes shape-five" />
      <img src={shape73} alt="" className="hidden md:block shapes shape-six" />
      <img
        src={shape74}
        alt=""
        className="hidden md:block shapes shape-seven"
      />
      <img
        src={shape75}
        alt=""
        className="hidden md:block shapes shape-eight"
      />
      <img src={shape76} alt="" className="hidden md:block shapes shape-nine" />
      <img src={shape77} alt="" className="hidden md:block shapes shape-ten" />
    </div>
  );
}

export default Banner;
