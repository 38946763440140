import { useTranslation } from "react-i18next";
import shape159 from "assets/shape/159.svg";
import BillingInfo from "./billing-info/BillingInfo";
import styles from "./pricing.module.css";

const Feature = ({
  packName,
  packNameColor,
  borderColor,
  price,
  packDetail,
  buttonActive,
  features,
  mostPopuler,
  aosDelay,
  btnText,
  packCode,
}) => (
  <div
    className={styles.price}
    data-aos="fade-up"
    data-aos-duration="1200"
    data-aos-delay={aosDelay}
  >
    <div
      style={{
        borderColor,
        borderWidth: "2px",
      }}
      className="flex flex-col justify-center items-center p-[20px] rounded-lg hover:border-black"
    >
      <div
        style={{
          backgroundColor: packNameColor,
        }}
        className="text-center text-2xl font-medium p-[5px] relative"
      >
        {mostPopuler && (
          <img
            className="absolute"
            style={{
              filter: "invert(1)",
              top: "-100px",
              right: "120px",
            }}
            src={shape159}
            alt=""
          />
        )}

        {packName}
      </div>
      <div className="text-5xl font-medium mt-[20px] mb-[15px]">{price}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          fontSize: "16px",
          letterSpacing: "2px",
          color: "#000",
          borderBottom: "1px solid #e0e0e0",
          padding: "0 0 27px",
        }}
      >
        {packDetail}
      </div>
      <div className={styles.feature}>
        {features.map((f) => (
          <p key={f}>{f}</p>
        ))}
      </div>
      <BillingInfo
        btnText={btnText}
        buttonActive={buttonActive}
        packCode={packCode}
      />
    </div>
  </div>
);

function Pricing() {
  const { t } = useTranslation();
  return (
    <div id="pricing-plan" className={styles.container}>
      <div
        style={{
          height: "150px",
        }}
      />
      <div className="flex flex-col items-center justify-center w-full">
        <p className={styles.title2}>{t("landingPage.pricing.ourPricing")}</p>
        <h2 className={styles.title1}>
          {t("landingPage.pricing.chooseYour")}
          <span>{t("landingPage.pricing.product")}</span>
        </h2>
        <div className={styles.priceContainer}>
          <Feature
            packName={t("landingPage.pricing.basic")}
            packCode="FREE"
            borderColor="#ececec"
            packNameColor="#FFECEC"
            price={t("landingPage.pricing.free")}
            packDetail={t("landingPage.pricing.takeATrial")}
            features={[
              t("landingPage.pricing.fullTestFree"),
              t("landingPage.pricing.miniTestFree"),
              t("landingPage.pricing.skillTestFree"),
              t("landingPage.pricing.grammarToeicFree"),
              t("landingPage.pricing.essentialVocabularyToeic"),
              t("landingPage.pricing.historyTest"),
              t("landingPage.pricing.noteDifficultQuestions"),
              t("landingPage.pricing.toeicTip"),
            ]}
            btnText={t("landingPage.pricing.tryItFree")}
          />
          <Feature
            aosDelay="100"
            packName="Premium"
            packCode="PREMIUM"
            borderColor="#000"
            packNameColor="#E3F8EF"
            price={t("landingPage.pricing.premiumPrice")}
            packDetail="BE MORE PRODUCTIVE"
            buttonActive
            features={[
              t("landingPage.pricing.AllFullTest"),
              t("landingPage.pricing.AllMiniTest"),
              t("landingPage.pricing.AllSkillTest"),
              t("landingPage.pricing.AllGrammarToeic"),
              t("landingPage.pricing.essentialVocabularyToeic"),
              t("landingPage.pricing.historyTest"),
              t("landingPage.pricing.noteDifficultQuestions"),
              t("landingPage.pricing.toeicTip"),
              t("landingPage.pricing.analyzeAndEvaluate"),
            ]}
            btnText={t("landingPage.pricing.buyNow")}
            mostPopuler
          />

          <Feature
            aosDelay="200"
            packNameColor="#FBF3E5"
            borderColor="#ececec"
            packName="Diamond"
            packCode="DIAMOND"
            price={t("landingPage.pricing.proPrice")}
            packDetail="GET MORE"
            features={[
              t("landingPage.pricing.AllFullTest"),
              t("landingPage.pricing.AllMiniTest"),
              t("landingPage.pricing.AllSkillTest"),
              t("landingPage.pricing.AllGrammarToeic"),
              t("landingPage.pricing.essentialVocabularyToeic"),
              t("landingPage.pricing.historyTest"),
              t("landingPage.pricing.noteDifficultQuestions"),
              t("landingPage.pricing.toeicTip"),
              t("landingPage.pricing.analyzeAndEvaluate"),
            ]}
            btnText={t("landingPage.pricing.buyNow")}
          />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
