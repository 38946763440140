import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./header.css";

function Header() {
  const [isFixed, setIsFixed] = useState(false);
  const [hiddenNav, setHiddenNav] = useState(true);
  const headerRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const sticky = headerRef.current.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      setIsFixed(window.pageYOffset > sticky);
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <div
      ref={headerRef}
      className={`toeicx-header flex justify-between items-center ${
        isFixed && "header-fixed"
      }`}
    >
      <a href="/#home">
        <div className="rubik-fonts logo">toeic X.</div>
      </a>

      <div className={`nav ${hiddenNav && "hidden-nav"} items-center mb-0`}>
        <div className="nav-item">
          <a href="/#home" onClick={() => setHiddenNav(true)}>
            {t("landingPage.header.home")}
          </a>
        </div>
        <div className="nav-item">
          <a href="/#about-toeicx" onClick={() => setHiddenNav(true)}>
            {t("landingPage.header.aboutToeicX")}
          </a>
        </div>
        <div className="nav-item">
          <a href="/#faq" onClick={() => setHiddenNav(true)}>
            {t("landingPage.header.faq")}
          </a>
        </div>
        <div className="nav-item">
          <a href="/#our-client" onClick={() => setHiddenNav(true)}>
            {t("landingPage.header.ourClient")}
          </a>
        </div>
        {/* <div className="nav-item">
          <a href="/#pricing-plan" onClick={() => setHiddenNav(true)}>
            {t("landingPage.header.pricingPlan")}
          </a>
        </div> */}
        <div className="language nav-item flex">
          <div
            className={`mr-10 en-language ${
              i18n.language.split("-")[0] === "en" && "active"
            }`}
            onClick={() => {
              setHiddenNav(true);
              i18n.changeLanguage("en");
            }}
          >
            En.
          </div>
          <div
            className={`vi-language ${
              i18n.language.split("-")[0] === "vi" && "active"
            }`}
            onClick={() => {
              setHiddenNav(true);
              i18n.changeLanguage("vi");
            }}
          >
            Vi.
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          setHiddenNav(!hiddenNav);
        }}
        className="toogle-nav"
      />
    </div>
  );
}

export default Header;
