import { Form, Input, Button, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

const api = axios.create({
  baseURL: "http://0.0.0.0:4000/external",
});

function ResetPassword() {
  const [form] = Form.useForm();
  const params = useParams();
  const history = useHistory();

  const onFinish = (values) => {
    api
      .post("/user/reset-password", {
        token: params.token,
        userID: params.userID,
        password: values.password,
      })
      .then(() => {
        notification.success({
          message: "Success",
          description: "Reset your password success",
        });
        history.push("/");
      })
      .catch((error) => {
        if (error.response) {
          notification.error({
            message: "Error",
            description: error.response.data
              ? error.response.data.error.message
              : "Have Error",
          });
        } else {
          notification.error({
            message: "Error",
            description: error.message,
          });
        }
      });
  };

  const validatePassword = (rule, value) => {
    if (value && form.getFieldValue("password") !== value) {
      return Promise.reject(
        new Error("Please input confirm password correctly")
      );
    }
    return Promise.resolve();
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <p
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          fontSize: "50px",
          fontWeight: "lighter",
        }}
      >
        Reset Password
      </p>
      <Form
        form={form}
        name="reset-password"
        onFinish={onFinish}
        layout="vertical"
        style={{
          marginRight: "auto",
          marginLeft: "auto",
        }}
        size="large"
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please input your confirm password!",
            },
            {
              validator: validatePassword,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ width: "100%", marginTop: "20px" }}
            type="primary"
            htmlType="submit"
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ResetPassword;
