import "./home.css";
import Banner from "./banner/Banner";
import FancyFeature from "./fancy-feature/FancyFeature";
import About from "./about/About";
import FAQ from "./faq/FAQ";
import OurClient from "./our-client/OurClient";
import Pricing from "./pricing/Pricing";
import FancyBanner from "./fancy-banner/FancyBanner";

function Home() {
  return (
    <>
      <Banner />
      <FancyFeature />
      <About />
      <FAQ />
      <OurClient />
      <Pricing />
      <FancyBanner />
    </>
  );
}

export default Home;
