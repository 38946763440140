/* eslint-disable react/button-has-type */
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Suspense } from "react";
import "./antTheme.less";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";
import ResetPassword from "./pages/ResetPassword";
import Home from "./pages/home/Home";
import Policy from "./pages/policy/Policy";
import Term from "./pages/term";
import Header from "./components/header/Header";
import Contact from "./components/contact/Contact";

AOS.init();

function App() {
  return (
    <Suspense fallback="loading">
      <div
        style={{
          marginTop: "150px",
          overflowX: "hidden",
        }}
      >
        <Router>
          <Header />
          <Switch>
            <Route
              path="/reset-password/:userID/:token"
              component={ResetPassword}
            />
            <Route path="/privacy-policy" component={Policy} />
            <Route path="/terms-and-conditions" component={Term} />

            <Route path="/" component={Home} />
          </Switch>
          <Contact />
        </Router>
      </div>
    </Suspense>
  );
}

export default App;
