import { useTranslation } from "react-i18next";
import ils10Img from "assets/shape/ils_10.svg";
import ils11Img from "assets/shape/ils_11.svg";
import ils12Img from "assets/shape/ils_12.svg";
import styles from "./about.module.css";

const SectionRL = ({ title1, title2, content, imageSrc }) => (
  <div className={styles.blockStyleTwelve}>
    <div className={styles.sectionContainer}>
      <div
        className={styles.sectionBanner}
        data-aos="fade-left"
        data-aos-duration="1200"
      >
        <img src={imageSrc} alt="" />
      </div>
      <div
        className={styles.sectionContentLeft}
        data-aos="fade-right"
        data-aos-duration="1200"
      >
        <h6 className={styles.titleH6}>{title1}</h6>
        <h2 className={styles.titleH2}>{title2}</h2>
        <p className={styles.content}>{content}</p>
      </div>
    </div>
  </div>
);

const SectionLR = ({ title1, title2, content, imageSrc }) => (
  <div className={styles.blockStyleTwelve}>
    <div className={styles.sectionContainer}>
      <div
        className={styles.sectionBanner}
        data-aos="fade-right"
        data-aos-duration="1200"
      >
        <img src={imageSrc} alt="" />
      </div>
      <div
        className={styles.sectionContent}
        data-aos="fade-left"
        data-aos-duration="1200"
      >
        <h6 className={styles.titleH6}>{title1}</h6>
        <h2 className={styles.titleH2}>{title2}</h2>
        <p className={styles.content}>{content}</p>
      </div>
    </div>
  </div>
);

function About() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className="flex justify-center items-center flex-col">
        <p className={styles.title1}>
          {t("landingPage.aboutToeicX.howItWorks")}
        </p>
        <h2 className={styles.title2}>{t("landingPage.aboutToeicX.title")}</h2>
        <SectionRL
          title1={t("landingPage.aboutToeicX.toeicTestTitle")}
          title2={t("landingPage.aboutToeicX.toeicTestSubTitle")}
          content={t("landingPage.aboutToeicX.toeicTestContent")}
          imageSrc={ils10Img}
        />
        <SectionLR
          title1={t("landingPage.aboutToeicX.grammarsTitle")}
          title2={t("landingPage.aboutToeicX.grammarsSubTitle")}
          content={t("landingPage.aboutToeicX.grammarsContent")}
          imageSrc={ils11Img}
        />
        <SectionRL
          title1={t("landingPage.aboutToeicX.analysisEvaluationTitle")}
          title2={t("landingPage.aboutToeicX.analysisEvaluationSubTitle")}
          content={t("landingPage.aboutToeicX.analysisEvaluationContent")}
          imageSrc={ils12Img}
        />
      </div>
    </div>
  );
}

export default About;
