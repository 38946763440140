import { useTranslation } from "react-i18next";
import { useState } from "react";
import shape120 from "assets/shape/120.svg";
import shape121 from "assets/shape/121.svg";

import styles from "./faq.module.css";

const Collapse = ({ header, content }) => {
  const [select, setSelect] = useState(false);
  return (
    <div className={styles.card}>
      <div
        className={styles.cardHeader}
        onClick={() => (select ? setSelect(false) : setSelect(true))}
        aria-hidden="true"
      >
        {header}
      </div>
      <div
        style={{
          maxHeight: select ? "500px" : "0px",
          overflow: "hidden",
          transition: "max-height 0.3s",
        }}
      >
        <div className={styles.cardBody}>{content}</div>
      </div>
    </div>
  );
};

function FAQ() {
  const { t } = useTranslation();
  return (
    <div
      id="faq"
      style={{
        paddingTop: "150px",
      }}
    >
      <div className={styles.faqSection}>
        <img
          src={shape120}
          alt=""
          className={`${styles.shapes} ${styles.shapeOne}`}
        />
        <img
          src={shape121}
          alt=""
          className={`${styles.shapes} ${styles.shapeTwo}`}
        />
        <div className={styles.container}>
          <div className="flex justify-center items-center flex-col w-full">
            <p className={styles.title1}>
              {t("landingPage.questionAnswer.FAQ")}
            </p>
            <h2 className={styles.title2}>
              {t("landingPage.questionAnswer.questionAnswer")}
            </h2>
            <div
              className={styles.contentContainer}
              data-aos-duration="1200"
              data-aos="fade-up"
            >
              <Collapse
                header={t("landingPage.questionAnswer.howDoesTheFreeTrialWork")}
                content={t(
                  "landingPage.questionAnswer.howDoesTheFreeTrialWorkAnswer"
                )}
              />
              <Collapse
                header={t(
                  "landingPage.questionAnswer.whatTypesOfAccountsDoesToeicXHave"
                )}
                content={
                  <div>
                    {t(
                      "landingPage.questionAnswer.currentlyToeicXHas3TypesOfAccounts"
                    )}
                    <br />
                    <b>1. {t("landingPage.questionAnswer.freeUser")}</b>
                    {t("landingPage.questionAnswer.freeUserDescription")}
                    <br />
                    <b>2. {t("landingPage.questionAnswer.premiumUser")}</b>
                    {t("landingPage.questionAnswer.premiumUserDescription")}
                    <br />
                    <b>3. {t("landingPage.questionAnswer.diamondUser")}</b>
                    {t("landingPage.questionAnswer.diamondUserDescription")}
                  </div>
                }
              />
              <Collapse
                header={t(
                  "landingPage.questionAnswer.howToPayForToeicXServices"
                )}
                content={t(
                  "landingPage.questionAnswer.howToPayForToeicXServicesAnswer"
                )}
              />
              <Collapse
                header={t(
                  "landingPage.questionAnswer.toeicXAutomaticallyDeductMoney"
                )}
                content={t(
                  "landingPage.questionAnswer.toeicXAutomaticallyDeductMoneyAnswer"
                )}
              />
              <Collapse
                header={t("landingPage.questionAnswer.canGetARefund")}
                content={t("landingPage.questionAnswer.canGetARefundAnswer")}
              />
              <Collapse
                header={t("landingPage.questionAnswer.whatPromotions")}
                content={t("landingPage.questionAnswer.whatPromotionsAnswer")}
              />
              <h3 className={styles.contactHeader}>
                {t("landingPage.questionAnswer.dontFindAnswer")}
              </h3>
              <div
                style={{ fontWeight: "400", fontSize: "18px", with: "auto" }}
                className="p-4 bg-green-500 text-white hover:cursor-pointer"
              >
                <a
                  style={{
                    color: "white",
                  }}
                  href="#contact-us"
                >
                  {t("landingPage.questionAnswer.contactUs")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
